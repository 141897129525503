// External Dependencies
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import React from 'react';
import { Link } from 'gatsby';

// Internal Dependencies
import { APP_NAME_SHORT } from '../utils/constants';
import { teamList } from '../utils/constants/team';
import CurveSection from '../components/shared/CurveSection';
import HeaderWithImage from '../components/HeaderWithImage';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import TeamMember from '../components/TeamMember';

// Component definition
const MeetTheTeam = () => {
  const title = `Meet the ${APP_NAME_SHORT} Team`;

  return (
    <Layout variant="curve">
      <Meta title={title} />

      <HeaderWithImage
        image={(
          <img
            alt="Presto Assistant logo"
            src="https://res.cloudinary.com/presto-assistant/image/upload/v1730047475/marketing/presto-stacked-logos-4x_szpnqk.png"
          />
        )}
        subtitle="Our team includes current and former music educators who understand the needs of fine arts teachers and administrators. We're building a 100-year company to be here for the long haul."
        title={title}
      />

      <div className="switch" id="main">
        <CurveSection
          className="main"
          id="content"
        />

        <Container maxWidth="md">
          <section className="team-list">
            {teamList.map((teamMember) => (
              <TeamMember
                imageUrl={teamMember.imageUrl}
                key={teamMember.name}
                name={teamMember.name}
                title={teamMember.title}
              />
            ))}
          </section>

          <Box className="panel">
            <Link
              className="transparent-button"
              data-variant="transport"
              to="/contact_support"
            >
              Contact us
            </Link>
          </Box>
        </Container>
      </div>
    </Layout>
  );
};

export default MeetTheTeam;
