// External Dependencies
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import React from 'react';
import PropTypes from 'prop-types';

// Local Variables
const propTypes = {
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

// Component Definition
const TeamMember = ({ imageUrl, name, title }) => {
  const initials = imageUrl ? '' : name.split(' ').map((n) => n[0]).join('');

  return (
    <Box
      className="team-member-panel"
      key={name}
    >
      {imageUrl
        ? (
          <img
            alt={name}
            className="team-member-image"
            src={imageUrl}
          />
        ) : (
          <Avatar className="team-member-image">
            {initials}
          </Avatar>
        )}
      <h5>
        {name}
      </h5>

      <p>
        {title}
      </p>
    </Box>
  );
};

TeamMember.propTypes = propTypes;

export default TeamMember;
