// External Dependencies
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

// Local Variables
const propTypes = {
  image: PropTypes.ReactNode,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

// Component Definition
const HeaderWithImage = ({
  image,
  subtitle = '',
  title = '',
}) => {
  return (
    <header
      className="curve-header"
      id="header"
    >
      <div className="link-wrapper">
        <Link to="/">
          <i className="fa fa-long-arrow-left" aria-hidden="true" /> Home
        </Link>
      </div>

      <section className="curve-header-top-row header-with-image">
        <div className="header-with-image-image-wrapper">
          {image}
        </div>

        <div className="header-with-image-text-wrapper">
          {title && <h2 className="title">{title}</h2>}
          {subtitle && <p>{subtitle}</p>}
        </div>
      </section>
    </header>
  );
};

HeaderWithImage.propTypes = propTypes;

export default HeaderWithImage;
