// eslint-disable-next-line import/prefer-default-export
export const teamList = [
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730041963/marketing/austin_chappell_zfkgzu.jpg',
    name: 'Austin Chappell',
    title: 'Co-Founder, Director of Engineering',
  },
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730042275/marketing/mike_mathew_diwd5c.jpg',
    name: 'Mike Mathew',
    title: 'Co-Founder, Director of Product',
  },
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730169866/marketing/james_barnes_brqpdh.jpg',
    name: 'James Barnes',
    title: 'Training and Support Specialist',
  },
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730054948/marketing/bonnye_kath_z35pqk.jpg',
    name: 'Bonnye Kath',
    title: 'Administrative Assistant',
  },
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730060667/marketing/nathan_lewis_w7gyze.jpg',
    name: 'Nathan Lewis',
    title: 'Software Engineer',
  },
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730061032/marketing/andrew_smit_o1lfqb.jpg',
    name: 'Andrew Smit',
    title: 'Software Engineer',
  },
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730060591/marketing/mark_mulligan_r6fvsl.jpg',
    name: 'Mark Mulligan',
    title: 'Software Engineer',
  },
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730112114/marketing/wendy_quadling_t3llpc.jpg',
    name: 'Wendy Quadling',
    title: 'Software Engineer',
  },
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730299364/marketing/katie_spoon_af3lut.jpg',
    name: 'Katie Spoon',
    title: 'Support Specialist',
  },
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730061508/marketing/alexis_mathew_nlxouj.jpg',
    name: 'Alexis Mathew',
    title: 'Support Specialist',
  },
  {
    imageUrl: 'https://res.cloudinary.com/presto-assistant/image/upload/v1730060825/marketing/katie_lewis_qdwztb.jpg',
    name: 'Katie Lewis',
    title: 'Social Media Guru',
  },
];
